<template>
  <v-card
    flat
    class="pa-3 mt-2 elevation-0"
  >
    <v-card-text>
      <v-form
        ref="form"
        class="multi-col-validation mt-6"
      >
        <v-row>
          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="dataComputed.fullname"
              :label="t('users.fullname')"
              dense
              outlined
              :readonly="option===2"
              :rules="[required]"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="6"
          >
            <v-select
              v-model="dataComputed.categories_ids"
              :label="t('Categories')"
              :items="categories"
              item-text="name"
              item-value="id"
              :menu-props="{ maxHeight: '400' }"
              multiple
              persistent-hint
              dense
              outlined
              class="mb-0"
              :append-icon="option === 2 ? null : icons.mdiMenuDown"
              :readonly="option===2"
              :rules="[]"
            >
              <template #selection="data">
                <v-chip
                  v-bind="data.attrs"
                  label
                  dense
                  :input-value="data.selected"
                  @click="data.select"
                >
                  {{ data.item.name }}
                </v-chip>
              </template>
            </v-select>
          </v-col>

          <v-col
            cols="12"
            md="6"
          >
            <div class="d-flex justify-space-between">
              <v-autocomplete
                v-model="phoneCode"
                dense
                outlined
                :items="phoneCodeOptions"
                item-text="text"
                item-value="value"
                style="
                  width: 35%;
                  max-width: 100px;
                "
                :append-icon="option===2 ? null : icons.mdiMenuDown"
                :readonly="option===2 || isUserComputed"
                hide-details
                :rules="[required]"
                :disabled="!!searchedUser || isUserComputed"
                @change="() => phoneNumber = null"
              >
                <template v-slot:item="{ item }">
                  <img
                    :src="item.image"
                    :alt="item.value"
                    width="20"
                    height="auto"
                    class="mr-2"
                  >
                  {{ item.text }}
                </template>
              </v-autocomplete>

              <v-text-field
                v-model="phoneNumber"
                :label="t('users.phone')"
                dense
                outlined
                :disabled="!phoneCode || !!searchedUser || isUserComputed"
                :readonly="option===2 || isUserComputed"
                class="pl-1"
                :rules="[required, validatePhone(phoneNumber, phoneCode)]"
                @keypress="onlyNumbers"
                @input="searchUser('phone')"
              ></v-text-field>
            </div>
          </v-col>

          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="dataComputed.email"
              :label="t('users.email')"
              dense
              outlined
              :readonly="option===2 || isUserComputed"
              :rules="[emailValidator]"
              :disabled="!!searchedUser || isUserComputed"
              @input="searchUser('email')"
            ></v-text-field>
          </v-col>

          <v-col
            v-if="searchedUser"
            cols="12"
            md="12"
          >
            <v-alert
              type="info"
              text
            >
              <v-avatar
                color="primary"
                :class="searchedUser.avatar ? '' : 'v-avatar-light-bg primary--text'"
                size="32"
              >
                <v-img
                  v-if="searchedUser.avatar"
                  :src="searchedUser.avatar"
                ></v-img>
                <span
                  v-else
                  class="font-weight-medium"
                >{{ avatarText(searchedUser.fullname) }}</span>
              </v-avatar>
              <span
                class="font-weight-bold ml-2"
              >{{ searchedUser.fullname }} tiene usuario en la app con ese número de teléfono/email</span>
            </v-alert>
          </v-col>
        </v-row>

        <options-buttom-block
          :tab-number="tab"
          :is-mode-save-edit="option !== 2"
          :no-save="false"
          :info-required="false"
          :no-cancel="option === 3"
          :no-arrow="true"
          @onCancel="onCancelButton"
          @onNextTab="changeTab"
          @onSave="onSaveAction"
        />
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import {
  mdiAlertOutline,
  mdiCloudUploadOutline,
  mdiMenuDown,
  mdiClose,
  mdiPencilOutline,
  mdiEyeOutline,
} from '@mdi/js'
import {
  ref,
  computed,
  watch,
  onBeforeMount,
  onMounted,
} from '@vue/composition-api'
import { required, emailValidator, integerValidator } from '@core/utils/validation'
import { useUtils } from '@core/libs/i18n'
import useCountry from '@core/utils/useCountry'
import usePhone from '@core/utils/usePhone'
import parsePhoneNumber from 'libphonenumber-js'
import { searchUserByPhoneEmail } from '@api'
import { avatarText } from '@core/utils/filter'
import { onlyNumbers } from '@core/utils/functionsHelpers'

import Vue from 'vue'
import { VueMaskDirective } from 'v-mask'

import OptionsButtomBlock from '@/views/components/options-buttom-block/OptionsButtomBlock.vue'

export default {
  components: {
    OptionsButtomBlock,
  },
  props: {
    isOpen: {
      type: Boolean,
      required: false,
    },
    option: {
      type: Number,
      default: 0,
    },
    tab: {
      type: Number,
      default: 0,
    },
    dataParams: {
      type: Object,
      default: () => {},
    },
    categories: {
      type: Array,
      required: true,
    },
    isUserApp: {
      type: Boolean,
      default: false,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { t } = useUtils()
    const { getCountryCode } = useCountry()
    const {
      phoneCodeOptions,
      initPhoneConfig,
      validatePhones,
    } = usePhone()

    const form = ref(null)
    const computedOpen = computed(() => props.isOpen)
    const computedOption = computed(() => props.option)
    const dataComputed = computed(() => props.dataParams)
    const isUserComputed = computed(() => props.isUserApp)

    watch([isUserComputed], () => {
    })

    const formDialog = ref(null)
    const isModalOpen = ref(false)
    const initCode = ref('')
    const phoneInputs = ref([
      { value: null, code: null, phone: null },
    ])
    const computedPhoneNumbers = computed(() => phoneInputs.value.filter(e => e.phone).map(e => e.phone).join(', '))

    const phoneCode = ref('')
    const phoneNumber = ref('')
    const searchedUser = ref(null)
    const timer = ref(null)

    const initialData = {
      facility_id: null,
      user_app_id: null,
      fullname: null,
      phone_code: null,
      phone_prefix: null,
      phone_number: null,
      email: null,
      categories_ids: [],
    }

    const statusOptions = computed(() => [
      { text: t('status.active'), value: 'A' },
      { text: t('status.inactive'), value: 'I' },
    ])

    const searchUser = async code => {
      if (timer.value) clearTimeout(timer.value)
      timer.value = setTimeout(async () => {
        let search = null
        if (code === 'phone') search = dataComputed.value.phone_number
        else if (code === 'email' && emailValidator(dataComputed.value.email) === true) search = dataComputed.value.email

        if (!searchedUser.value && ((search && code === 'phone') || (search && code === 'email' && emailValidator(dataComputed.value.email) === true))) {
          const response = await searchUserByPhoneEmail(search)
          if (response.ok) {
            searchedUser.value = response.data
            dataComputed.value.user_app_id = response.data.username

            // dataComputed.value.phone_number = response.data.phone_number

            if (code === 'email') {
              const phoneNumberSearch = parsePhoneNumber(response.data.phone_number)
              phoneNumber.value = phoneNumberSearch.nationalNumber
              phoneCode.value = phoneNumberSearch.country

              dataComputed.value.phone_number = phoneNumberSearch.number
              dataComputed.value.phone_code = phoneNumberSearch.country
              dataComputed.value.phone_prefix = `+${phoneNumberSearch.countryCallingCode}`
            } else {
              dataComputed.value.email = response.data.email
            }
          } else {
            searchedUser.value = null
            dataComputed.value.user_app_id = null
          }
        } else {
          searchedUser.value = null
          dataComputed.value.user_app_id = null
        }
      }, 300)
    }

    const initPhone = () => {
      setTimeout(() => {
        if (props.option === 1) {
          const countryCode = getCountryCode()
          if (countryCode) {
            const codeFinded = phoneCodeOptions.value.find(e => e.value === countryCode)
            if (codeFinded) initCode.value = countryCode
            else initCode.value = 'US'
          }
          phoneInputs.value[0].code = initCode.value
          phoneCode.value = initCode.value

          dataComputed.value.status = 'A'
        }

        if (props.option !== 1) {
          if (props.dataParams.phone_number) {
            const config = initPhoneConfig(props.dataParams.phone_number)
            phoneCode.value = config.code
            phoneNumber.value = config.phone_number
          }
        }
      }, 500)
    }

    const onSaveAction = () => {
      if (form.value.validate()) {
        dataComputed.value.phone_numbers = phoneInputs.value.filter(e => e.phone).map(e => e.phone)
        emit('save', dataComputed.value)
      }
    }

    const onCancelButton = async () => {
      form.value.reset()
      searchedUser.value = null
      phoneCode.value = null
      phoneNumber.value = null
      dataComputed.value.phone_number = null
      dataComputed.value.phone_code = null
      dataComputed.value.phone_prefix = null
      dataComputed.value.email = null
      dataComputed.value.categories_ids = []
      dataComputed.value.status = 'A'
      dataComputed.value.user_app_id = null
      dataComputed.value.fullname = null

      await initPhone()
      emit('updateIsUserApp', false)
    }

    const changeTab = tab => {
      if (form.value.validate()) {
        emit('changeTab', tab, true, dataComputed.value)
      }
    }

    const addPhoneNumber = () => {
      if (phoneInputs.value.length < 30) {
        phoneInputs.value.push({ value: null, code: initCode.value, phone: null })
      }
    }

    const deletePhoneNumber = index => {
      phoneInputs.value.splice(index, 1)
    }

    const validatePhone = (e, code) => {
      if (code && e && /^([0-9])*$/.test(e)) {
        const phoneNumberJS = parsePhoneNumber(e, code)
        if (phoneNumberJS) {
          if (phoneNumberJS.isValid()) {
            dataComputed.value.phone_number = phoneNumberJS.number
            dataComputed.value.phone_code = phoneNumberJS.country
            dataComputed.value.phone_prefix = `+${phoneNumberJS.countryCallingCode}`

            return true
          }
        }
      }

      return 'Invalid number'
    }

    watch([isModalOpen], () => {
      if (isModalOpen.value) {
        setTimeout(() => {
          const btn = document.getElementById('addBtnForm')
          btn.addEventListener('click', addPhoneNumber)
        }, 200)
      }
    })

    watch([computedOpen], async () => {
      if (!computedOpen.value) await onCancelButton()
      else if (computedOption.value > 1) await initPhone()
    })

    onBeforeMount(() => {
      Vue.directive('mask', VueMaskDirective)
    })

    onMounted(async () => {
      await initPhone()
    })

    return {
      // data
      form,
      initialData,

      formDialog,
      isModalOpen,
      phoneInputs,
      computedPhoneNumbers,
      phoneCodeOptions,

      phoneCode,
      phoneNumber,
      searchedUser,

      // computed
      dataComputed,
      statusOptions,
      isUserComputed,

      // methods
      onCancelButton,
      changeTab,
      onSaveAction,
      addPhoneNumber,
      deletePhoneNumber,
      searchUser,
      avatarText,

      // validations
      required,
      emailValidator,
      integerValidator,
      validatePhone,
      validatePhones,
      onlyNumbers,

      // i18n
      t,

      icons: {
        mdiAlertOutline,
        mdiCloudUploadOutline,
        mdiMenuDown,
        mdiClose,
        mdiPencilOutline,
        mdiEyeOutline,
      },
    }
  },
}
</script>
